import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.png`} alt="" />
      </Link>
      <header>
        <h2>Rodolfo Maciéczyk</h2>
        <p><a href="mailt&#111;&#58;h&#105;&#64;rodolfom&#46;d&#101;%76">hi[at]rodolfom.dev</a></p>
        {/* <p><a href="mailto:hi@rodolfom.dev">hi@rodolfom.dev</a></p> */}
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>
        Hi, I&apos;m Rodolfo. I like building things. I hold a Master of Science in Data Analytics and a Computer Science degree from <a href="https://www.wgu.edu/">Western Governors University</a>.
      </p>

      <p>
        I&apos;m currently pursuing an MS in Computer Science at <a href="https://www.gatech.edu">Georgia Tech</a> 👨‍🎓. Professionally, I&apos;m a Decision Science Product Consultant at Disney Decision Science and Integration (DDSI) within <a href="https://www.thewaltdisneycompany.com">The Walt Disney Company</a>. Previously, I contributed as a Research Volunteer at <a href="https://iee-unsjconicet.org/">IEE-CONICET</a>.
      </p>

      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Rodolfo Maciéczyk <Link to="/">rodolfom.dev</Link>.</p>
    </section>
  </section>
);

export default SideBar;
